<template>
  <div>
    <a
      v-for="(icon) in platforms"
      :key="icon.index"
      v-html="icon['icon']"
      :href="icon['link']"
      :class="iconClass"
      target="_blank"
      rel="noopener"
      :aria-label="'follow us on ' + icon['name']"
    ></a>
  </div>
</template>

<script>
export default {
  props: {
    iconClass: {
      type: String,
      default: '.25rem'
    },
    platforms: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
